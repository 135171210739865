import {Component} from "react";

let T_LEVELS = [
    [5.0, 11.0],
    [11.1, 15.0],
    [15.1, 17.0],
    [17.1, 20.0],
    [20.1, 26.0],
    [26.1, 29.0],
    [29.1, 33.0],
    [33.1, 39.0],
    [39.1, 45.0]
];

let H_LEVELS = [
    [10.0, 15.0],
    [15.1, 20.0],
    [20.1, 35.0],
    [35.1, 40.0],
    [40.1, 50.0],
    [50.1, 65.0],
    [65.1, 75.0],
    [75.1, 87.0],
    [87.1, 100.0]
];

let NOISE_LEVELS = [
    [0.0, 39.0],
    [40.0, 59.0],
    [60.0, 79.0],
    [80.0, 119.0],
    [120.0, 180.0]
];

let CO2_LEVELS = [
    [413.0, 599.0],
    [600.0, 999.0],
    [1000.0, 1499.0],
    [1500.0, 2499.0],
    [2500.0, 4000.0]
];

let PM25_LEVELS = [
    [0.0, 20.0],
    [21.0, 50.0],
    [51.0, 90.0],
    [91.0, 140.0],
    [141.0, 200.0]
];

let VOC_LEVELS = [
    [0.0, 0.087],
    [0.088, 0.261],
    [0.262, 0.660],
    [0.661, 2.200],
    [2.201, 3.00]
];

let O3_LEVELS = [
    [0.0, 0.025],
    [0.026, 0.060],
    [0.061, 0.075],
    [0.076, 0.100],
    [0.101, 0.300]
];

let CO_LEVELS = [
    [0.0, 1.7],
    [1.8, 8.7],
    [8.8, 10.0],
    [10.1, 15],
    [15.1, 30]
];

let CH2O_LEVELS = [
    [0.0, 0.05],
    [0.06, 0.10],
    [0.11, 0.30],
    [0.31, 0.75],
    [0.76, 1.0]
];

let AVTI_LEVELS = [
    [0, 1],
    [2, 3],
    [4, 5]
];

let faces3 = [
    "good",
    "polluted",
    "severely-polluted"
];

let faces5 = [
    "good",
    "moderate",
    "polluted",
    "very-polluted",
    "severely-polluted"
];

let faces5n = [
    "good",
    "good",
    "polluted",
    "very-polluted",
    "severely-polluted"
];

let faces9 = [
    "severely-polluted",
    "very-polluted",
    "polluted",
    "moderate",
    "good",
    "moderate",
    "polluted",
    "very-polluted",
    "severely-polluted"
];

function round(value, decimals) {
    let m = 10 ** decimals;
    return Math.round((value + Number.EPSILON) * m) / m
}


function getHappyFace(value, levels, faces, decimals) {
    if(value === undefined) {
        return "";
    }
    value = round(value, decimals);
    if (value < levels[0][0]) {
        return faces[0];
    }
    let index = 0;
    for (let i in levels) {
        if (value >= levels[i][0] && value <= levels[i][1]) {
            break
        }
        index++;
    }
    if (index > faces.length - 1) {
        index = faces.length - 1;
    }
    return faces[index];
}

class Atmocube extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            co2: undefined,
            pm25: undefined,
            tvoc: undefined,
            t: undefined,
            h: undefined,
            noise: undefined
        };
    }

    componentDidMount() {
        this.fetchUsers();
        this.timer = setInterval(() => this.fetchUsers(), 60000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    fetchUsers() {
        this.setState({...this.state, isFetching: true});
        fetch('https://atmocube-config.uc.r.appspot.com/data_latest',
            {
                method: 'GET',
                headers: {'Authorization': 'APIKey 23a25c56f2a181449fcde693c138c3479af14662'}
            })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                let device_id = '5Yvj3Yl';
                this.setState({
                    co2: result[device_id]['co2'],
                    pm25: result[device_id]['pm25'],
                    tvoc: result[device_id]['voc'],
                    t: result[device_id]['temperature'],
                    h: result[device_id]['humidity'],
                    noise: result[device_id]['noise'],
                    dtm: result[device_id]['dtm'],
                    o3: result[device_id]['o3'],
                    co: result[device_id]['co'],
                    ch2o: result[device_id]['ch2o'],
                    avti: result[device_id]['avti'],
                    isFetching: false
                })
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    }

    render() {
        return (
            <section className="tv-section">
                <div className="left-side-wrap">
                    <div className="logo-wrap">
                        <a href="https://atmotube.com" target="_blank" className="ac-logo w-inline-block"></a>
                        <div className="name-last-date">
                            <h4 className="h4-small-title">Device name</h4>
                            <div className="ac-name">Stand A344, Pavilion 2</div>
                        </div>
                        <div className="name-last-date">
                            <h4 className="h4-small-title">last update</h4>
                            <div className="ac-name">{this.state.dtm === undefined ? "--" : new Date(Date.parse(this.state.dtm)).toLocaleString("pt-pt")}</div>
                        </div>
                    </div>
                    <div className="co-logo_wrap"><img src="websummit.png" loading="lazy" alt=""
                                                       className="company__logo_image"/></div>
                    <div className="txt-wrap">
                        <h1 className="txt-header">Explore air quality with Atmocube&#x27;s sensors.</h1>
                        <a href="https://atmotube.com/pages/atmocube_app" target="_blank"
                           className="qr-code w-inline-block"></a>
                    </div>
                </div>
                <div className="right-side-wrap">
                    <div className="w-layout-grid sensors-grid">
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <h1 className="sensor-heading big">CO<sub>2</sub></h1>
                                <div className={`sensor-icon ${getHappyFace(this.state.co2, CO2_LEVELS, faces5, 0)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.co2 === undefined ? "--" : this.state.co2}</h2>
                                <h4 className="sensor-measurement">ppm</h4>
                            </div>
                        </div>
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <h1 className="sensor-heading big">PM<sub>2.5</sub></h1>
                                <div className={`sensor-icon ${getHappyFace(this.state.pm25, PM25_LEVELS, faces5, 0)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.pm25 === undefined ? "--" : this.state.pm25}</h2>
                                <h4 className="sensor-measurement">μg/m<sup>3</sup></h4>
                            </div>
                        </div>
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <h1 className="sensor-heading big">TVOC<span className="sensor-special-txt"></span></h1>
                                <div className={`sensor-icon ${getHappyFace(this.state.tvoc, VOC_LEVELS, faces5, 3)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.tvoc === undefined ? "--" : this.state.tvoc}</h2>
                                <h4 className="sensor-measurement">ppm</h4>
                            </div>
                        </div>
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <h1 className="sensor-heading big">O<sub>3</sub></h1>
                                <div className={`sensor-icon ${getHappyFace(this.state.o3, O3_LEVELS, faces5, 3)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.o3 === undefined ? "--" : this.state.o3}</h2>
                                <h4 className="sensor-measurement">ppm</h4>
                            </div>
                        </div>
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <h1 className="sensor-heading big">CO</h1>
                                <div className={`sensor-icon ${getHappyFace(this.state.co, CO_LEVELS, faces5, 1)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.co === undefined ? "--" : (Math.round(this.state.co * 100) / 100).toFixed(2)}</h2>
                                <h4 className="sensor-measurement">ppm</h4>
                            </div>
                        </div>
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <h1 className="sensor-heading big">CH<sub>2</sub>O</h1>
                                <div className={`sensor-icon ${getHappyFace(this.state.ch2o, CH2O_LEVELS, faces5, 2)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.ch2o === undefined ? "--" : this.state.ch2o}</h2>
                                <h4 className="sensor-measurement">ppm</h4>
                            </div>
                        </div>
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <div className="svg-icon"></div>
                                <div className={`sensor-icon ${getHappyFace(this.state.t, T_LEVELS, faces9, 1)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.t === undefined ? "--" : (Math.round(this.state.t * 10) / 10)}{this.state.t === undefined ? "" : "º"}</h2>
                                <h4 className="sensor-measurement">сelsius</h4>
                            </div>
                        </div>
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <div className="svg-icon hum"></div>
                                <div className={`sensor-icon ${getHappyFace(this.state.h, H_LEVELS, faces9, 0)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.h === undefined ? "--" : Math.round(this.state.h)}</h2>
                                <h4 className="sensor-measurement">%</h4>
                            </div>
                        </div>
                        <div className="sensor-wrap">
                            <div className="sensor-name-icon-wrap">
                                <h1 className="sensor-heading big">Virus index</h1>
                                <div className={`sensor-icon ${getHappyFace(this.state.avti, AVTI_LEVELS, faces3, 0)}`}/>
                            </div>
                            <div className="sensor-data-txt">
                                <h2 className="sensor-data-text">{this.state.avti === undefined ? "--" : this.state.avti}</h2>
                                <h4 className="sensor-measurement">{this.state.avti === undefined ? "--" : this.state.avti == 1 ? "low" : this.state.avti >= 4 ? "high" : "moderate"}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

function App() {

    return (
        <Atmocube/>
    );
}

export default App;
